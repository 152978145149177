export  function getDefaultHost() {
    //const host = process.env.NODE_ENV === 'development'?'/api':'https://doctor.er18.xyz/api/'//测试环境
    //const host = process.env.NODE_ENV === 'development'?'/api':'https://dp.er18.xyz/api/'//套餐测试环境
    const host = process.env.NODE_ENV === 'development'?'/api':' https://doctor.unifamily.cn/api/'//正式环境
    return host
}
export function getDefaultUrl(){
    //const url = 'https://doctor.er18.xyz/'//测试环境
    //const url = 'https://dp.er18.xyz/'//套餐测试环境
    const url = 'https://doctor.unifamily.cn/'//正式环境
    return url
}